<template>
  <div class="share" :class="{safari:os.safari,on:pageLoaded}">
      <div class="share-logo">
            <div class="share-cover"></div>
            <van-image :src="require('../../assets/img/logo2.png')" :width="150 / 37.5 + 'rem'" :height="24 / 37.5 + 'rem'" fit="cover" loading-icon="" error-icon=""></van-image>
      </div>
      <div class="share-txt">
            <div class="t1">HI,朋友<br/>邀请你加入享设计</div>
            <div class="t2">上传作品天天分红，一起来赚钱吧</div>
      </div>
      <div class="share-info">
          <div class="title">你的朋友</div>
          <div class="name">{{inviteData.nickname}}</div>
          <div class="avatar">
              <div class="share-cover"></div>
            <van-image :src="inviteData.head_img" :width="50 / 37.5 + 'rem'" :height="50 / 37.5 + 'rem'" fit="cover" loading-icon="" error-icon=""></van-image>
          </div>
      </div>
      <div class="share-bottom-bg">
            <div class="share-cover">
                <div class="share-operate">
                    <div class="title">注册时使用我的邀请码</div>
                    <div class="code">{{inviteData.invatation_code}}</div>
                    <div class="btn" @click="onPageToRegister">点击注册</div>
                    <div class="cue">轻按手机右上角分享按钮<br/>发送给朋友或者分享到朋友圈</div>
                </div>
            </div>
            <van-image :src="require('../../assets/img/share-img.png')" :width="347 / 37.5 + 'rem'" :height="299 / 37.5 + 'rem'" fit="cover" loading-icon="" error-icon=""></van-image>
      </div>
  </div>
</template>

<script>
import { Image } from "vant";
import { mapActions, mapState } from "vuex";
export default {
    components: {
        [Image.name]: Image,
    },
    data() {
        return {
            pageLoaded: false,
        };
    },
    computed: {
        ...mapState({
            inviteData: (state) => state.me.userInviteData,
            os: (state) => state.os,
        }),
    },
    methods: {
        ...mapActions({
            requestInvite: "me/requestInvite",
        }),
        onPageToRegister() {
            this.$cookies.set('share_invite_code', this.inviteData.invatation_code, 60 * 60 * 24 * 7)
            this.$router.push({
                name: "home",
            });
        },
    },
    created() {

        document.title = "邀请你加入享设计";

        let _code = this.$route.query.code;
        if (_code) {
            this.requestInvite({ invite_code: _code }).then(() => {
                this.pageLoaded = true;
            });
        } else {
            this.$router.replace({
                name: "home",
            });
        }
    },
};
</script>

<style>
.share{height: 100vh; overflow: hidden; opacity: 0;}
.share.on{opacity: 1;}
.share.safari{height: calc(100vh - 75px); position:fixed; left: 0; top: 0 ; z-index: 1; width: 100%;}
.share-cover{width:100%;height:100%;position:absolute;left:0;top:0;z-index:100}
.share-logo{width: 150px; height: 24px; margin: calc(32px * 2) auto;}
.share-bottom-bg{width:347px; height: 299px; position: absolute; right: 0; bottom: 0; z-index: 1;}
.share-txt{margin-bottom: calc(32px * 2);}
.share-txt .t1{text-align: center; font-size: 14px; color: #2E2E30; line-height: 22px;}
.share-txt .t2{text-align: center; font-size: 16px; color: #F85659; margin-top: 6px;}
.share-info .title{font-size: 14px;color: #2E2E30; text-align: center; margin-bottom: 5px;}
.share-info .name{font-size: 14px;color: #F85659; text-align: center;}
.share-info .avatar{width:50px; height: 50px; border-radius: 50%; overflow: hidden; background: #ededed; margin: 12px auto 0;}
.share-operate{transform-origin: 50% 50%; transform:rotate(-15deg); position: absolute; left: 80px; top: 100px; z-index: 1;}
.share-operate .title{font-size: 12px; color:#F85659 ; text-align: center;}
.share-operate .code{font-size: 24px; color:#F85659 ; text-align: center; margin-top: 5px;}
.share-operate .btn{width:96px; height: 36px; text-align: center; line-height: 36px; font-size: 14px; margin: 20px auto 10px; color: #fff; background: #F85659; border-radius: 18px; overflow: hidden;}
.share-operate .cue{font-size: 12px; color: #2E2E30; text-align: center; line-height: 17px;}

/* ------------------------------------------------------ 16:9(标准屏安卓手机、18:9的浏览器、iphone5/6/7/8) ------------------------------------------------------*/
@media screen and (orientation:portrait) and (width: 360px) and (max-height: 580px), screen and (orientation:portrait) and (width: 375px) and (max-height: 605px), screen and (orientation:portrait) and (width: 393px) and (max-height: 630px), screen and (orientation:portrait) and (width: 412px) and (max-height: 660px), screen and (orientation:portrait) and (width: 414px) and (max-height: 675px),
screen and (orientation:landscape) and (height: 360px) and (max-width: 580px), screen and (orientation:landscape) and (height: 375px) and (max-width: 605px), screen and (orientation:landscape) and (height: 393px) and (max-width: 630px), screen and (orientation:landscape) and (height: 412px) and (max-width: 660px), screen and (orientation:landscape) and (height: 414px) and (max-width: 675px),screen and (orientation:portrait) and (width: 320px) and (max-height: 570px){
    .share-logo{ margin: 32px auto;}
	.share-txt{margin-bottom: 32px;}
}

/*------------------------------------ 15:9（标准屏+虚拟系统按键安卓手机（华为P9）的微信、16:9的浏览器 、iphone5/6/7/8的微信底部有历史工具条/浏览器）--------------------*/
@media screen and (orientation:portrait) and (width: 360px) and (max-height: 540px), screen and (orientation:portrait) and (width: 375px) and (max-height: 560px), screen and (orientation:portrait) and (width: 393px) and (max-height: 590px), screen and (orientation:portrait) and (width: 414px) and (max-height: 630px),
screen and (orientation:landscape) and (height: 360px) and (max-width: 540px), screen and (orientation:landscape) and (height: 375px) and (max-width: 560px), screen and (orientation:landscape) and (height: 393px) and (max-width: 590px), screen and (orientation:landscape) and (height: 414px) and (max-width: 630px){
    .share-logo{ margin: 32px auto;}
	.share-txt{margin-bottom: 32px;}
}
</style>